import type { CategoryProps } from '../../types';

import * as React from 'react';
import styled from '@emotion/styled';
import { useInView } from 'react-intersection-observer';
import { useAppContext } from '../../contexts/AppContext';
import { Link } from '../../components/Link';
import { CollectionSection } from '../../components/CollectionSection';
import { Title } from '../../components/Title';
import { CardTypeEnum } from '../../types/enum';
import { Card } from '../../components/Card';
import { Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: theme.spacing(2),
}));

const Wrapper = styled('div')(({ theme }) => ({
  maxWidth: 1220,
  margin: 'auto',
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(0, 2),
  },
}));

const ProductContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  // marginTop: theme.spacing(5),
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: theme.spacing(5),
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: theme.spacing(3, 2),
    marginTop: theme.spacing(3),
  },
}));

const DescriptionTitle = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(5),
  color: theme.theme_component_navigation_bar_background_color,
  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(3),
  }
}));

const PageTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(5.3),
  fontWeight: 'bold',
  color: theme.theme_component_navigation_bar_background_color,
  marginTop: theme.spacing(6),
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
    fontSize: theme.spacing(3.8),
  }
}));

const CardStyled = styled(Card)(({ theme, template, hoverEffect, cardBorder }: any) => ({
  width: '100%',
  backgroundColor: theme.theme_component_card_background_color || theme.palette.background.paper,
  boxShadow: theme.theme_component_card_box_shadow || theme.shadows[3],
  borderRadius: template === 'big' ? 0 : `${theme.theme_component_card_border_radius}px` || theme.shape.borderRadius,
  border: cardBorder || `${theme.theme_component_card_content_border}px` || 'none',
  borderStyle: theme.theme_component_card_content_border ? 'solid' : 'none',
  borderColor: theme.theme_component_card_content_border ? theme.theme_component_card_content_border_color : 'none',
  transition: `transform ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeInOut}`,
  '&:hover': hoverEffect && {
    transform: 'scale3d(1.05, 1.05, 1)',
  },
  '& .card-img': {
    height: "auto !important"
  },
  '& .inner-card': {
    height: "auto !important",
    '& > div:first-of-type': {
      height: "auto !important"
    },
    '& > div:last-of-type': {
      height: "auto !important"
    },
  },
  
  
}));


export const CollectionsF = (props: any) => {
  const { collections, pageContext } = props;
  const appContext = useAppContext();
  const { assetUrl, componentOptions } = appContext.useConfig();
  const { ref, inView } = useInView();
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  const collectionsBeforeAdContainer =
    collections?.filter((c: any) => !c.code.includes('recommended')).slice(0, 1) || [];

  const gamesArray = collectionsBeforeAdContainer.flatMap((collection: any) => collection.games);

  const products =
    collections?.length > 1
      ? collections?.filter((c: any) => !c.code.includes('recommended')).slice(1) || []
      : [];

  return (
    <Wrapper>
      <PageTitle variant='h1'>Games</PageTitle>
      <DescriptionTitle>Test your knowledge with our free collection of games and puzzles—right here on CNN.</DescriptionTitle>
      <Root ref={ref}>
        {gamesArray.length > 0 && (
          <ProductContainer className="wrapper-for-cards">
            {gamesArray.slice(0).map((game: any, index: number) => (
              <React.Fragment key={game.id}>
                <CardStyled
                  key={game.id}
                  href={`${`/game/${game.slug}`}`}
                  src={`${assetUrl}${
                    [1, 4, 7].includes(index)
                      ? game.images.cover4x3 || game.images.cover
                      : game.images.cover16x9 || game.images.cover
                  }/`}
                  description={game.description || ""}
                  alt={game.name}
                  title={game.name || undefined}
                  sx={{ display: 'flex' }}
                  template={CardTypeEnum.CNN}
                  width={1280}
                  height={720}
                  priority={index === 1}
                  layout="responsive"
                />
              </React.Fragment>
            ))}
            <CardStyled
              href={`https://cnn.it/5thingsquiz`}
              src={`/images/cnn-activites.png`}
              description="What do you remember from the week that was?"
              alt="5 Things Quiz"
              title="5 Things Quiz"
              sx={{ display: 'flex' }}
              template={CardTypeEnum.CNNActivities}
              width={1280}
              height={720}
              layout="responsive"
            />
          </ProductContainer>
        )}
        {inView &&
          products.map(
            (collection: CategoryProps, index: number) =>
              collection.games.length > 0 && (
                <CollectionSection key={`${collection.id}${index}`} style={{ marginTop: '48px' }}>
                  <Title component="h2" variant="h2" style={{ padding: 0 }}>
                    <Link
                      href={`/${collection.type}/${collection.slug}`}
                      title={collection.name || undefined}
                      underline="hover"
                    >
                      {collection.name}
                    </Link>
                  </Title>
                  <ProductContainer key={index}>

                  {collection?.games?.map((game: any, gIndex: number) => {
                    return (
                      <>
                        <CardStyled
                          key={`${game.id}_${gIndex}`}
                          href={`${`/game/${game.slug}`}`}
                          src={`${assetUrl}${
                            [1, 4, 7].includes(index)
                              ? game.images.cover4x3 || game.images.cover
                              : game.images.cover16x9 || game.images.cover
                          }/`}
                          description={game.description || ""}
                          alt={game.name}
                          title={game.name || undefined}
                          sx={{ display: 'flex' }}
                          template={CardTypeEnum.CNN}
                          width={1280}
                          height={720}
                          priority={index === 1}
                          layout="responsive"
                        />
                       
                      </>
                      
                    )
                  })}
                  </ProductContainer>
                </CollectionSection>
              )
          )}
      </Root>
    </Wrapper>
  );
};
